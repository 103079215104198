import { useState } from 'react'
import { storageService } from '../../../Storage/services/service'
import { Kpi } from '../../services/types'

const latestKpiKey = 'iqbin_latest_kpi'

const state = {
    getLatestKpi() {
        return storageService.get<Kpi>(latestKpiKey) || undefined
    },

    setLatestKpi(kpi?: Kpi, callback?: (kpi?: Kpi) => void) {
        if (kpi === undefined) {
            storageService.remove(latestKpiKey)
        } else {
            storageService.set(latestKpiKey, kpi)
        }

        callback && callback(kpi)
    },
}

const useSharedState = () => {
    const [kpi, setKpi] = useState<Kpi | undefined>(state.getLatestKpi())

    return { latestKpy: kpi, setLatestKpi: (kpi?: Kpi) => state.setLatestKpi(kpi, setKpi) }
}

export default useSharedState
