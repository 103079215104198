import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'

import styles from './AnalyseView.module.scss'
import { Auth } from '../../../Auth/components/Auth/Auth'
import { PageLayout } from '../../../Core/components/Layout/PageLayout/PageLayout'
import { config } from '../../../../config'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../Core/components/ActivityIndicator/ActicvityIndicator'

export const AnalyseView: FunctionComponent = () => {
    return (
        <Auth>
            <AnalyseViewLayout />
        </Auth>
    )
}

const AnalyseViewLayout: FunctionComponent = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), config.deley)
    }, [])

    return (
        <PageLayout
            loading={loading}
            content={() => (
                <Suspense fallback={<ActivityIndicator size={ActivityIndicatorEnumType.large} />}>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 1229 689"
                            >
                                <defs>
                                    <linearGradient id="73nimuxhlc" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="8nq0vwj32d" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="445azxd27g" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#1AA5AB" />
                                        <stop offset="100%" stopColor="#0B6C73" />
                                    </linearGradient>
                                    <linearGradient id="w4xd77vflh" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="gmdxwz5n6m" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#1AA5AB" />
                                        <stop offset="100%" stopColor="#0B6C73" />
                                    </linearGradient>
                                    <linearGradient id="fvbqlj7h9p" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#1AA5AB" />
                                        <stop offset="100%" stopColor="#0B6C73" />
                                    </linearGradient>
                                    <linearGradient id="4a6pe1sosq" x1="50%" x2="7.572%" y1="41.808%" y2="54.032%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="7gus9j8jbr" x1="50%" x2="50%" y1="0%" y2="99.8%">
                                        <stop offset="0%" stopColor="#D0FBDF" />
                                        <stop offset="100%" stopColor="#8CE9AE" />
                                    </linearGradient>
                                    <linearGradient id="dmoage4xpu" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="exr9iyagmv" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="77qvbnhs1w" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="wxuxy07pcx" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="di5lklmksy" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="8lwgqgx45z" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="h5rvnd62zA" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="gzhdve13aB" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="7er3k96rmC" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="wm52epymrD" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="gem5umpcvE" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="uh0yewtboF" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="8bhgomfzmG" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#F8B2AD" />
                                        <stop offset="100%" stopColor="#EF7B75" />
                                    </linearGradient>
                                    <linearGradient id="8o1mwdhu1H" x1="50%" x2="50%" y1="0%" y2="100%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="ie60zimg3K" x1=".1%" x2="99.9%" y1="49.9%" y2="49.9%">
                                        <stop offset="0%" stopColor="#B7FED1" />
                                        <stop offset="100%" stopColor="#8CE9AE" />
                                    </linearGradient>
                                    <linearGradient id="u11g34bp5L" x1="0%" x2="100%" y1="50%" y2="50%">
                                        <stop offset="0%" stopColor="#95ECF4" />
                                        <stop offset="100%" stopColor="#5CD5E5" />
                                    </linearGradient>
                                    <linearGradient id="xexbhvbj2M" x1="0%" x2="100%" y1="50%" y2="50%">
                                        <stop offset="0%" stopColor="#1AA5AB" />
                                        <stop offset="100%" stopColor="#0B6C73" />
                                    </linearGradient>
                                    <filter
                                        id="nilvhyqffa"
                                        width="103.2%"
                                        height="103.5%"
                                        x="-1.6%"
                                        y="-1.3%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <filter
                                        id="oo0m5y4n4e"
                                        width="103.3%"
                                        height="107.4%"
                                        x="-1.6%"
                                        y="-2.6%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <filter
                                        id="ssaxmwq0wi"
                                        width="103.3%"
                                        height="119%"
                                        x="-1.6%"
                                        y="-6.8%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <filter
                                        id="gojb4wlktk"
                                        width="105.6%"
                                        height="104.2%"
                                        x="-2.8%"
                                        y="-1.5%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <filter
                                        id="azimctlf6n"
                                        width="102.7%"
                                        height="104.2%"
                                        x="-1.3%"
                                        y="-1.5%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <filter
                                        id="6dngw62v9s"
                                        width="102.7%"
                                        height="104.2%"
                                        x="-1.3%"
                                        y="-1.5%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <filter
                                        id="scbge16vsI"
                                        width="105.6%"
                                        height="104.2%"
                                        x="-2.8%"
                                        y="-1.5%"
                                        filterUnits="objectBoundingBox"
                                    >
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur
                                            in="shadowOffsetOuter1"
                                            result="shadowBlurOuter1"
                                            stdDeviation="2"
                                        />
                                        <feComposite
                                            in="shadowBlurOuter1"
                                            in2="SourceAlpha"
                                            operator="out"
                                            result="shadowBlurOuter1"
                                        />
                                        <feColorMatrix
                                            in="shadowBlurOuter1"
                                            values="0 0 0 0 0.0431372549 0 0 0 0 0.423529412 0 0 0 0 0.450980392 0 0 0 0.25 0"
                                        />
                                    </filter>
                                    <rect id="5i0fbo6m5b" width="431" height="397" x="0" y="0" rx="8" />
                                    <rect id="jehr884m5f" width="430" height="190" x="0" y="0" rx="8" />
                                    <rect id="5tbekcrxlj" width="430" height="73.828" x="0" y="0" rx="8" />
                                    <rect id="6hsk14fqgl" width="250" height="335" x="0" y="0" rx="8" />
                                    <rect id="dpqgkr3vlo" width="520" height="335" x="0" y="0" rx="8" />
                                    <rect id="biqop2v67t" width="520" height="335" x="0" y="0" rx="8" />
                                    <rect id="5n56b9iuuJ" width="250" height="335" x="0" y="0" rx="8" />
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000)">
                                                        <use
                                                            fill="#000"
                                                            filter="url(#nilvhyqffa)"
                                                            xlinkHref="#5i0fbo6m5b"
                                                        />
                                                        <use fill="#FFF" fillOpacity=".85" xlinkHref="#5i0fbo6m5b" />
                                                    </g>
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="18"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000)"
                                                    >
                                                        <tspan x="30" y="44">
                                                            Voor en na
                                                        </tspan>
                                                    </text>
                                                    <g>
                                                        <path
                                                            d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(381.000000, 25.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g fill="#D7D9E3">
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 93.691983)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 93.691983)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    80
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 46.845992)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 46.845992)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    100
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    120
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 140.537975)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 140.537975)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    60
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 187.383966)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 187.383966)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    40
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 234.229958)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 234.229958)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    20
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H371V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 281.075949)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(0.000000, 281.075949)"
                                                            >
                                                                <tspan x="0" y="14.924">
                                                                    Bins
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                    </g>
                                                    <g fill="#D7D9E3" fontFamily="Lato-Regular, Lato" fontSize="12">
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x=".146" y="12">
                                                                Wo
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x="56.146" y="12">
                                                                Do
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x="109.266" y="12">
                                                                Vr
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x="159.214" y="12">
                                                                Za
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x="210.034" y="12">
                                                                Zo
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x="261.438" y="12">
                                                                Ma
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000) translate(41.000000, 284.000000)">
                                                            <tspan x="316.446" y="12">
                                                                Di
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <path
                                                        stroke="url(#73nimuxhlc)"
                                                        strokeLinecap="round"
                                                        strokeWidth="3"
                                                        d="M40 69s320.486 0 325-.5"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000)"
                                                    />
                                                    <path
                                                        stroke="url(#8nq0vwj32d)"
                                                        strokeLinecap="round"
                                                        strokeWidth="3"
                                                        d="M40.985 183.169s25.45 7.099 52.857 7.099c26.428 0 26.428-12.17 53.836-12.17 26.428 0 26.428 28.902 53.836 28.902 26.428 0 22.513-36 49.92-36 26.429 0 24.47 12.169 51.878 12.169 26.429 0 61.688 8.624 61.688 8.624"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(30.000000, 75.000000)"
                                                    />
                                                </g>
                                                <g>
                                                    <g
                                                        fill="#5CD5E5"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(271.000000, 31.742462)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                Nu
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g
                                                        fill="#EF7B75"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 283.000000) translate(271.000000, 31.742462) translate(60.000000, 0.000000)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" fillRule="nonzero" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                Voor
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000)">
                                                        <use
                                                            fill="#000"
                                                            filter="url(#oo0m5y4n4e)"
                                                            xlinkHref="#jehr884m5f"
                                                        />
                                                        <use fill="#FFF" fillOpacity=".85" xlinkHref="#jehr884m5f" />
                                                    </g>
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="18"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000)"
                                                    >
                                                        <tspan x="30" y="44">
                                                            Duurzaamheid
                                                        </tspan>
                                                    </text>
                                                    <g>
                                                        <path
                                                            d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(380.000000, 25.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g>
                                                    <path
                                                        fill="url(#445azxd27g)"
                                                        fillRule="nonzero"
                                                        d="M39.58 0c.552 0 1 .448 1 1v.496h5.402c1.878-.011 3.598 1.01 4.446 2.649l3.137 5.986 3.695 1.567c1.138.487 1.865 1.592 1.84 2.756l.978 12.148c.002.026.003.053.003.08 0 .552-.448 1-1 1h-9.31C49.19 29.04 47.82 30 46.218 30c-2.132 0-3.852-1.686-3.852-3.777 0-.092.003-.183.01-.273H18.656c.006.09.01.181.01.273 0 2.079-1.732 3.777-3.852 3.777-2.132 0-3.852-1.686-3.852-3.777 0-.092.003-.183.01-.273H3.004C1.321 25.926-.024 24.572 0 22.922V2C0 .895.896 0 2 0zM13.748 2.377H5.246c-1.418 0-2.52 1.152-2.52 2.542l-.06 15.434h1.873l9.21-17.976zm32.234 1.507H40.58v5.938h10.126l-2.422-4.596c-.449-.844-1.345-1.354-2.302-1.342z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(30.000000, 75.000000)"
                                                    />
                                                    <g>
                                                        <text
                                                            fill="#0B6B73"
                                                            fontFamily="Futura-Medium, Futura"
                                                            fontSize="36"
                                                            fontWeight="400"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(30.000000, 75.000000) translate(0.000000, 40.000000)"
                                                        >
                                                            <tspan x="0" y="37">
                                                                458
                                                            </tspan>
                                                        </text>
                                                        <text
                                                            fill="#0B6C73"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(30.000000, 75.000000) translate(0.000000, 40.000000)"
                                                        >
                                                            <tspan x="75" y="37">
                                                                gereden
                                                            </tspan>
                                                        </text>
                                                        <text
                                                            fill="#0B6C73"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(30.000000, 75.000000) translate(0.000000, 40.000000)"
                                                        >
                                                            <tspan x="75" y="22">
                                                                km minder
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <text
                                                            fill="#5CD5E5"
                                                            fontFamily="Futura-Medium, Futura"
                                                            fontSize="36"
                                                            fontWeight="400"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(229.000000, 73.000000) translate(0.000000, 42.000000)"
                                                        >
                                                            <tspan x="0" y="37">
                                                                56
                                                            </tspan>
                                                        </text>
                                                        <text
                                                            fill="#0B6C73"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(229.000000, 73.000000) translate(0.000000, 42.000000)"
                                                        >
                                                            <tspan x="53" y="37">
                                                                bespaard
                                                            </tspan>
                                                        </text>
                                                        <text
                                                            fill="#0B6C73"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(229.000000, 73.000000) translate(0.000000, 42.000000)"
                                                        >
                                                            <tspan x="53" y="22">
                                                                ton CO2
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <path
                                                        fill="url(#w4xd77vflh)"
                                                        fillRule="nonzero"
                                                        d="M23.675.195c4.378-.78 8.226.79 11.431 4.595l.237.287.299-.105c2.388-.81 4.541-.907 6.436-.254l.297.11c2.136.837 3.473 2.738 4.045 5.57l.013.074.314.01c8.531.34 13.102 4.073 13.25 11.047l.003.352C60 29.74 55.798 33.909 47.767 34H15.718C5.453 33.912 0 30.657 0 24.165c0-6.056 3.601-9.519 10.406-10.192l.204-.02.05-.33C11.826 6.208 16.075 1.69 23.265.271zm19.789 20.584c-1.515 0-2.397.826-2.646 2.48l-.029.22h1.259c.123-1.022.607-1.533 1.454-1.533.405 0 .745.135 1.02.404.275.27.412.603.412 1.002 0 .276-.103.588-.31.935-.1.177-.248.397-.441.659-.155.21-.34.45-.553.72l-.166.21-2.908 3.59h5.626v-1.211h-3.12l1.302-1.572c.362-.435.661-.812.898-1.132.237-.319.417-.594.54-.827.243-.446.364-.902.364-1.367 0-.73-.26-1.343-.782-1.837-.517-.494-1.157-.741-1.92-.741zm-20.89-8.04c-1.93 0-3.501.73-4.717 2.192-.987 1.194-1.48 2.552-1.48 4.076 0 1.699.59 3.15 1.769 4.354 1.185 1.193 2.615 1.79 4.291 1.79 1.013 0 1.984-.242 2.913-.724l.277-.152v-2.177c-.288.248-.563.46-.824.635-.26.176-.512.315-.755.418-.436.207-.965.31-1.588.31-1.205 0-2.223-.43-3.053-1.293-.83-.863-1.246-1.922-1.246-3.177 0-1.27.415-2.345 1.246-3.223.825-.883 1.84-1.325 3.046-1.325 1.015 0 2 .377 2.955 1.13l.22.18v-2.139c-.983-.583-2-.875-3.054-.875zm10.786.008c-1.692 0-3.14.604-4.345 1.813-1.21 1.208-1.815 2.654-1.815 4.338 0 1.916.663 3.466 1.99 4.649 1.205 1.069 2.565 1.603 4.079 1.603 1.711 0 3.172-.601 4.382-1.805 1.205-1.198 1.808-2.657 1.808-4.377 0-1.71-.6-3.174-1.8-4.393-1.195-1.219-2.628-1.828-4.3-1.828zm-.023 1.696c1.225 0 2.253.434 3.084 1.302.835.862 1.253 1.932 1.253 3.207 0 1.26-.423 2.33-1.269 3.208-.845.867-1.883 1.301-3.114 1.301-1.134 0-2.134-.426-3-1.278-.87-.852-1.306-1.94-1.306-3.262 0-1.26.42-2.319 1.26-3.176.841-.868 1.872-1.302 3.092-1.302z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 83.000000) translate(229.000000, 73.000000)"
                                                    />
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000)">
                                                        <use
                                                            fill="#000"
                                                            filter="url(#ssaxmwq0wi)"
                                                            xlinkHref="#5tbekcrxlj"
                                                        />
                                                        <use fill="#FFF" fillOpacity=".85" xlinkHref="#5tbekcrxlj" />
                                                    </g>
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="18"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000)"
                                                    >
                                                        <tspan x="30" y="44">
                                                            Tijd
                                                        </tspan>
                                                    </text>
                                                    <g>
                                                        <path
                                                            d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(380.000000, 25.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path
                                                                d="M112.463 30H9.18C4.11 30 0 27.314 0 24V6c0-3.314 4.11-6 9.18-6h103.283"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(199.301310, 0.000000)"
                                                            />
                                                            <path
                                                                stroke="#D7D9E3"
                                                                d="M112.463 30H9.18C4.11 30 0 27.314 0 24V6c0-3.314 4.11-6 9.18-6h103.283"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(199.301310, 0.000000) translate(56.231441, 15.000000) scale(-1, 1) translate(-56.231441, -15.000000)"
                                                            />
                                                            <path
                                                                d="M112.463 30L0 30 0 0 112.463 0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(199.301310, 0.000000) translate(56.231441, 15.000000) scale(-1, 1) translate(-56.231441, -15.000000)"
                                                            />
                                                        </g>
                                                        <g fill="#5CD5E5">
                                                            <path
                                                                d="M24.295 2c.552 0 1 .448 1 1v1h2c1.597 0 2.903 1.318 2.995 2.98l.005.187v12.666c0 1.687-1.25 3.065-2.824 3.162l-.176.005h-19C6.697 23 5.39 21.682 5.3 20.02l-.005-.187V7.167c0-1.687 1.249-3.065 2.823-3.162L8.295 4h2V3c0-.552.447-1 1-1 .552 0 1 .448 1 1v1h11V3c0-.552.447-1 1-1zm-1 4h-11c0 .552-.448 1-1 1-.553 0-1-.448-1-1h-2c-.516 0-.94.418-.994.955l-.006.116V19.93c0 .552.39 1.007.89 1.065l.11.006h19c.515 0 .94-.418.994-.955l.006-.116V7.07c0-.552-.39-1.007-.891-1.065L27.294 6h-2c0 .552-.447 1-1 1-.552 0-1-.448-1-1zm-11 11c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm-4-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm-12 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm4 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zm-12 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(199.301310, 0.000000) translate(38.436681, 3.000000)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M105.345 30H8.6C3.85 30 0 27.314 0 24V6c0-3.314 3.85-6 8.6-6h96.745"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(93.956332, 0.000000)"
                                                        />
                                                        <path
                                                            d="M105.345 30H8.6C3.85 30 0 27.314 0 24V6c0-3.314 3.85-6 8.6-6h96.745"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(93.956332, 0.000000) translate(52.672489, 15.000000) scale(-1, 1) translate(-52.672489, -15.000000)"
                                                        />
                                                        <path
                                                            stroke="#D7D9E3"
                                                            d="M105.345 30L0 30 0 0 105.345 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(93.956332, 0.000000) translate(52.672489, 15.000000) scale(-1, 1) translate(-52.672489, -15.000000)"
                                                        />
                                                        <text
                                                            fill="#5CD5E5"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(93.956332, 0.000000)"
                                                        >
                                                            <tspan x="25.231" y="22">
                                                                Maand
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            fill="#0B6C73"
                                                            d="M93.956 30H7.67C3.434 30 0 27.314 0 24V6c0-3.314 3.434-6 7.67-6h86.286"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000)"
                                                        />
                                                        <path
                                                            d="M93.956 30H7.67C3.434 30 0 27.314 0 24V6c0-3.314 3.434-6 7.67-6h86.286"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(46.978166, 15.000000) scale(-1, 1) translate(-46.978166, -15.000000)"
                                                        />
                                                        <path
                                                            d="M93.956 30L0 30 0 0 93.956 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000) translate(46.978166, 15.000000) scale(-1, 1) translate(-46.978166, -15.000000)"
                                                        />
                                                        <text
                                                            fill="#D6F5F9"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(790.000000, 0.000000) translate(88.000000, 22.000000)"
                                                        >
                                                            <tspan x="23.803" y="22">
                                                                Week
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)">
                                                        <use
                                                            fill="#000"
                                                            filter="url(#gojb4wlktk)"
                                                            xlinkHref="#6hsk14fqgl"
                                                        />
                                                        <use fill="#FFF" fillOpacity=".85" xlinkHref="#6hsk14fqgl" />
                                                    </g>
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="18"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                    >
                                                        <tspan x="30" y="44">
                                                            Besparing
                                                        </tspan>
                                                    </text>
                                                    <g>
                                                        <path
                                                            d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000) translate(200.000000, 25.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g fill="#0B6C73">
                                                    <text
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="24"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000) translate(124.000000, 274.000000)"
                                                    >
                                                        <tspan x="0" y="25">
                                                            14 200
                                                        </tspan>
                                                    </text>
                                                    <text
                                                        fontFamily="Lato-Regular, Lato"
                                                        fontSize="18"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000) translate(124.000000, 274.000000)"
                                                    >
                                                        <tspan x="85" y="25">
                                                            €
                                                        </tspan>
                                                    </text>
                                                </g>
                                                <path
                                                    fill="#D6F5F9"
                                                    d="M30 257H220V259H30z"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                />
                                                <text
                                                    fill="#8CE9AE"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="18"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                >
                                                    <tspan x="160.852" y="228">
                                                        6 100 €
                                                    </tspan>
                                                </text>
                                                <text
                                                    fill="#8CE9AE"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="18"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                >
                                                    <tspan x="88.672" y="228">
                                                        289u
                                                    </tspan>
                                                </text>
                                                <g fill="#8CE9AE" fillRule="nonzero">
                                                    <path
                                                        d="M20.376 16.8c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zM22.8 1.2c.663 0 1.2.537 1.2 1.2v1.2h2.4c1.917 0 3.484 1.582 3.594 3.577L30 7.4v15.2c0 2.013-1.483 3.661-3.36 3.792.611-1.058.96-2.284.96-3.592V7.286c0-.663-.468-1.209-1.07-1.278L26.4 6H24c0 .663-.537 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2H8.4c0 .663-.537 1.2-1.2 1.2C6.537 7.2 6 6.663 6 6H3.6c-.619 0-1.128.501-1.193 1.146l-.007.14v15.428c0 .663.468 1.209 1.07 1.278L3.6 24l9.7.001c.144.862.442 1.671.864 2.4L3.6 26.4c-1.917 0-3.484-1.582-3.594-3.577L0 22.6V7.4c0-2.024 1.499-3.678 3.388-3.794L3.6 3.6H6V2.4c0-.663.537-1.2 1.2-1.2.663 0 1.2.537 1.2 1.2v1.2h13.2V2.4c0-.663.537-1.2 1.2-1.2zm-2.4 17.4l-.108.01c-.245.044-.438.237-.482.482l-.01.108v3h-2.4l-.108.01c-.28.05-.492.295-.492.59s.212.54.492.59l.108.01h3l.108-.01c.245-.044.438-.237.482-.482L21 22.8v-3.6l-.01-.108c-.05-.28-.295-.492-.59-.492zm-12 .6c.331 0 .6.269.6.6V21c0 .331-.269.6-.6.6H7.2c-.331 0-.6-.269-.6-.6v-1.2c0-.331.269-.6.6-.6h1.2zm4.8 0c.331 0 .6.269.6.6v.119c-.23.527-.4 1.087-.499 1.67l-.101.011H12c-.331 0-.6-.269-.6-.6v-1.2c0-.331.269-.6.6-.6h1.2zm0-4.8c.331 0 .6.269.6.6v1.2c0 .331-.269.6-.6.6H12c-.331 0-.6-.269-.6-.6V15c0-.331.269-.6.6-.6h1.2zm-4.8 0c.331 0 .6.269.6.6v1.2c0 .331-.269.6-.6.6H7.2c-.331 0-.6-.269-.6-.6V15c0-.331.269-.6.6-.6h1.2zm9.6 0c.331 0 .6.269.6.6v.827c-.745.192-1.444.5-2.075.904-.194-.098-.325-.3-.325-.531V15c0-.331.269-.6.6-.6H18zm4.8 0c.331 0 .6.269.6.6v1.2l-.004.051c-.74-.339-1.546-.556-2.395-.626L21 15c0-.331.269-.6.6-.6h1.2zm-9.6-4.8c.331 0 .6.269.6.6v1.2c0 .331-.269.6-.6.6H12c-.331 0-.6-.269-.6-.6v-1.2c0-.331.269-.6.6-.6h1.2zm4.8 0c.331 0 .6.269.6.6v1.2c0 .331-.269.6-.6.6h-1.2c-.331 0-.6-.269-.6-.6v-1.2c0-.331.269-.6.6-.6H18zm4.8 0c.331 0 .6.269.6.6v1.2c0 .331-.269.6-.6.6h-1.2c-.331 0-.6-.269-.6-.6v-1.2c0-.331.269-.6.6-.6h1.2zm-14.4 0c.331 0 .6.269.6.6v1.2c0 .331-.269.6-.6.6H7.2c-.331 0-.6-.269-.6-.6v-1.2c0-.331.269-.6.6-.6h1.2z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000) translate(30.000000, 206.000000)"
                                                    />
                                                </g>
                                                <text
                                                    fill="#5CD5E5"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="18"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                >
                                                    <tspan x="160.852" y="160">
                                                        2 350 €
                                                    </tspan>
                                                </text>
                                                <text
                                                    fill="#5CD5E5"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="18"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                >
                                                    <tspan x="78.988" y="160">
                                                        1505L
                                                    </tspan>
                                                </text>
                                                <path
                                                    fill="#5CD5E5"
                                                    fillRule="nonzero"
                                                    d="M46.632 135c1.539 0 2.847 1.005 3.322 2.404l4.751 6.448c.215.291.317.65.291 1.01l-.02.155-3.36 19.048-.002.052c-.057.497-.472.883-.976.883H30v-26.429c0-1.908 1.473-3.467 3.327-3.566l.187-.005h13.118zm3.514 5.724V163h.492l.105.006 1.83-16.757-1.387-.943-1.04-4.582zm-10.071 9.742c-.294 0-.513.14-.659.418l-1.666 3.16c-.138.263-.276.544-.414.844-.137.3-.252.61-.346.93-.093.32-.14.644-.14.973 0 .446.083.862.25 1.247.165.386.395.725.69 1.017.293.292.635.52 1.026.686.39.166.81.249 1.259.249.449 0 .868-.083 1.257-.249.389-.165.73-.394 1.026-.686.296-.292.526-.631.693-1.017.166-.385.25-.801.25-1.247 0-.329-.048-.653-.141-.973-.094-.32-.21-.63-.349-.93-.139-.3-.278-.581-.416-.844l-1.661-3.16c-.155-.279-.375-.418-.66-.418zm6.557-14.037H33.514c-1.114 0-2.026.877-2.103 1.99l-.005.152v5H48.74v-5c0-1.082-.79-1.977-1.816-2.122l-.148-.015-.145-.005z"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                />
                                                <text
                                                    fill="#0B6C73"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="18"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                >
                                                    <tspan x="160.852" y="92">
                                                        5 750 €
                                                    </tspan>
                                                </text>
                                                <text
                                                    fill="#0B6C73"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="18"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                >
                                                    <tspan x="105.304" y="92">
                                                        1.1
                                                    </tspan>
                                                </text>
                                                <path
                                                    fill="url(#gmdxwz5n6m)"
                                                    fillRule="nonzero"
                                                    d="M56.055 75c.551 0 .998.447.998.998h3.602c1.251-.008 2.398.673 2.963 1.765l2.092 3.99 2.463 1.046c.759.325 1.243 1.06 1.227 1.837l.623 7.738.003.08c0 .553-.448 1-1 1l-5.846.001c-.388.906-1.3 1.545-2.369 1.545-1.42 0-2.568-1.124-2.568-2.518 0-.061.003-.122.007-.182H42.438c.004.06.006.121.006.182 0 1.386-1.154 2.518-2.568 2.518-1.421 0-2.568-1.124-2.568-2.518 0-.061.003-.122.007-.182h-5.312c-1.123-.016-2.02-.918-2.003-2.019V77c0-1.105.895-2 2-2zm-16.89 1.585h-5.668c-.945 0-1.68.768-1.68 1.694l-.04 10.29h1.249l6.14-11.984zm21.49 1.004h-3.602v3.959h6.751l-1.615-3.064c-.299-.562-.897-.903-1.534-.895z"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(530.000000, 346.000000)"
                                                />
                                            </g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000)">
                                                            <use
                                                                fill="#000"
                                                                filter="url(#azimctlf6n)"
                                                                xlinkHref="#dpqgkr3vlo"
                                                            />
                                                            <use
                                                                fill="#FFF"
                                                                fillOpacity=".85"
                                                                xlinkHref="#dpqgkr3vlo"
                                                            />
                                                        </g>
                                                        <text
                                                            fill="#0B6C73"
                                                            fontFamily="Futura-Medium, Futura"
                                                            fontSize="18"
                                                            fontWeight="400"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000)"
                                                        >
                                                            <tspan x="30" y="44">
                                                                Soort afval
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(470.000000, 25.000000)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g fill="#D7D9E3">
                                                        <g>
                                                            <path
                                                                d="M0 0H460V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000)"
                                                            >
                                                                <tspan x="0" y="15">
                                                                    100%
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H460V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 43.800000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 43.800000)"
                                                            >
                                                                <tspan x="0" y="15">
                                                                    80%
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H460V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 87.600000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 87.600000)"
                                                            >
                                                                <tspan x="0" y="15">
                                                                    60%
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H460V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 131.400000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 131.400000)"
                                                            >
                                                                <tspan x="0" y="15">
                                                                    40%
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H460V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 175.200000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 175.200000)"
                                                            >
                                                                <tspan x="0" y="15">
                                                                    20%
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                        <g>
                                                            <path
                                                                d="M0 0H460V1H0z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 219.000000)"
                                                            />
                                                            <text
                                                                fontFamily="Lato-Regular, Lato"
                                                                fontSize="12"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(30.000000, 75.000000) translate(0.000000, 219.000000)"
                                                            >
                                                                <tspan x="0" y="15">
                                                                    0%
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                    </g>
                                                    <g fill="#D7D9E3" fontFamily="Lato-Regular, Lato" fontSize="12">
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x=".146" y="12">
                                                                Wo
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x="63.979" y="12">
                                                                Do
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x="124.933" y="12">
                                                                Vr
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x="182.714" y="12">
                                                                Za
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x="241.367" y="12">
                                                                Zo
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x="300.605" y="12">
                                                                Ma
                                                            </tspan>
                                                        </text>
                                                        <text transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(92.000000, 297.000000)">
                                                            <tspan x="363.446" y="12">
                                                                Di
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g opacity=".85">
                                                        <path
                                                            d="M0 0H420V200H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(70.000000, 94.000000)"
                                                        />
                                                        <path
                                                            fill="url(#fvbqlj7h9p)"
                                                            d="M0 120s15.48 10 31.99 10c16.511 0 16.511-86 31.99-86 8.256 0 12.384 6 16.511 12l.506.734C84.963 62.48 89.084 68 97.002 68h31.99c8.256 0 12.384 18.5 16.383 37l.48 2.219C149.688 124.95 153.552 142 160.982 142c16.51 0 16.51-78 33.022-78 7.095 0 10.937-4.622 14.609-9.628l.5-.685.499-.687.5-.687.505-.685c3.71-5.006 7.81-9.628 15.377-9.628 16.42 0 16.51 142.426 32.752 143.987l.001-.01c-11.046-.039-18.957-2.479-20.37-8.012-6.773-26.527-6.468-40.475-5.793-54.607l.08-1.664c.64-13.055 1.37-26.692-3.058-50.899-.38-2.08-7.195-1.368-10.836 0-12.354 4.642-18.297 4.926-21.154 12.02-6.054 15.025-5.128 23.69-4.123 31.868l.128 1.042c1.084 8.841 1.915 17.404-6.325 33.194-3.966 7.601-15.338 5.354-29.926 11.017-10.914 4.237-21.405 8.578-31.474 13.021V88.824c-22.816 1.738-38.123 5.41-45.922 11.017-16.165 11.622 1.81 15.439-10.319 29.046-15.992 17.943-31.983 31.318-45.921 34.054L16.51 142.41 0 148.919V120zm420-55.715c-10.918-27.996-17.97-38.846-21.155-32.552-16.635 32.876 2.618 60.445-20.639 117.186-4.864 11.868-12.635 12.601-35.602 20.032-21.922 7.092-28.162 3.715-54.176 9.014-15.345 3.126-15.572 7.75-28.298 8.002 6.925-.404 10.605-4.588 14.36-8.815l.54-.607c3.877-4.343 8-8.545 15.977-8.545 8.492 0 12.616-1.587 16.725-3.129l.685-.256c3.663-1.361 7.504-2.615 14.58-2.615 8.256 0 12.384-3 16.512-6l.652-.473c3.921-2.834 8.038-5.527 15.859-5.527 15.479 0 15.479-132 31.99-132C404.52 18 420 36 420 36z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(70.000000, 94.000000)"
                                                        />
                                                        <path
                                                            fill="url(#4a6pe1sosq)"
                                                            d="M0 148.919l16.511-6.51 7.077 20.118C12.932 166.542 5.07 174.366 0 186v-37.081zM420 109c-9.181 9.688-18.711 19.354-28.59 29-14.076 13.744-7.634 14.506-29.199 27.425-10.32 7.075-26.83 7.075-38.182 9.075-4.273.753-7.641 2.043-11.209 3.557l-.672.286c-5.733 2.455-12.148 5.43-23.72 7.657-13.804 2.657-16.457 2.002-29.965 4.737-14.041 2.843-27.272 5.93-39.692 9.263V98c-9.975 43-17.542 66.667-22.702 71-7.74 6.5-19.3 3.35-31.99 5.368-8.944 1.421-21.671 5.299-38.182 11.632v-13.043c10.07-4.443 20.56-8.784 31.474-13.02 14.588-5.664 25.96-3.417 29.926-11.018 8.24-15.79 7.41-24.353 6.325-33.194l-.128-1.042c-1.005-8.178-1.93-16.843 4.123-31.869 2.857-7.093 8.8-7.377 21.154-12.019 3.641-1.368 10.455-2.08 10.836 0 4.429 24.207 3.698 37.844 3.059 50.9l-.08 1.663c-.676 14.132-.98 28.08 5.792 54.607 1.425 5.578 9.453 8.013 20.64 8.013 13.837 0 13.63-4.799 29.41-8.013 26.014-5.3 32.254-1.922 54.176-9.014 22.967-7.43 30.738-8.164 35.602-20.032 23.257-56.741 4.004-84.31 20.64-117.186C402.03 25.44 409.081 36.29 420 64.285zm-294.103 20c-4.797-3.147-25.47-2.955-30.958 0-14.448 11.5-14.448 22.5-26.77 31-6.769 0-23.28 2-34.63 0-.556.082-1.106.172-1.65.27 11.862-5.314 24.814-16.85 37.767-31.383 12.128-13.607-5.846-17.424 10.32-29.046 7.798-5.606 23.105-9.279 45.92-11.017z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(70.000000, 94.000000)"
                                                        />
                                                        <path
                                                            fill="url(#7gus9j8jbr)"
                                                            d="M0 186c6.536-15 17.715-23.667 33.538-26 11.351 2 27.862 0 34.63 0 12.323-8.5 12.323-19.5 26.77-31 5.488-2.955 26.162-3.147 30.959 0v57c16.51-6.333 29.238-10.21 38.182-11.632 12.69-2.017 24.25 1.132 31.99-5.368 5.16-4.333 12.727-28 22.702-71v102c12.42-3.332 25.65-6.42 39.692-9.263 13.508-2.735 16.161-2.08 29.965-4.737 19.286-3.712 24.25-9.5 35.601-11.5 11.352-2 27.863-2 38.182-9.075 21.565-12.92 15.123-13.681 29.2-27.425 9.878-9.646 19.408-19.312 28.589-29v91H0v-14z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(70.000000, 94.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g
                                                        fill="#5CD5E5"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(246.000000, 32.000000) translate(78.000000, 0.000000)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                PMD
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g
                                                        fill="#0B6C73"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(246.000000, 32.000000)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                Papier
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g
                                                        fill="#8CE9AE"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(0.000000, 346.000000) translate(246.000000, 32.000000) translate(150.000000, 0.000000)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" fillRule="nonzero" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                Restafval
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000)">
                                                        <use
                                                            fill="#000"
                                                            filter="url(#6dngw62v9s)"
                                                            xlinkHref="#biqop2v67t"
                                                        />
                                                        <use fill="#FFF" fillOpacity=".85" xlinkHref="#biqop2v67t" />
                                                    </g>
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="18"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000)"
                                                    >
                                                        <tspan x="30" y="44">
                                                            Tijd geleegd
                                                        </tspan>
                                                    </text>
                                                    <g>
                                                        <path
                                                            d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(470.000000, 25.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g fill="#D7D9E3">
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                120
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 73.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 73.000000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                80
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 36.500000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 36.500000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                100
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 109.500000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 109.500000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                60
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 146.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 146.000000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                40
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 182.500000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 182.500000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                20
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <path
                                                            d="M0 0H460V1H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 219.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(30.000000, 75.000000) translate(0.000000, 219.000000)"
                                                        >
                                                            <tspan x="0" y="15">
                                                                Bins
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path
                                                                fill="url(#dmoage4xpu)"
                                                                d="M4 90.713c2.21 0 4 1.791 4 4V127H0V94.713c0-2.209 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(0.000000, 88.001151)"
                                                            />
                                                            <path
                                                                fill="url(#exr9iyagmv)"
                                                                d="M20 0c2.21 0 4 1.79 4 4v122.999h-8V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(0.000000, 88.001151)"
                                                            />
                                                        </g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(0.000000, 88.001151)"
                                                        >
                                                            <tspan x="3.146" y="141.999">
                                                                Wo
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(64.000000, 37.999083)"
                                                        >
                                                            <tspan x="4.146" y="192.001">
                                                                Do
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                fill="url(#77qvbnhs1w)"
                                                                d="M4 0c2.21 0 4 1.79 4 4v173H0V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(64.000000, 37.999083)"
                                                            />
                                                            <path
                                                                fill="url(#wxuxy07pcx)"
                                                                d="M20 76.432c2.21 0 4 1.791 4 4v96.569h-8V80.432c0-2.209 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(64.000000, 37.999083)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(128.000000, 54.997712)"
                                                        >
                                                            <tspan x="6.266" y="175.002">
                                                                Vr
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                fill="url(#di5lklmksy)"
                                                                d="M4 41.258c2.21 0 4 1.791 4 4v114.744H0V45.258c0-2.209 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(128.000000, 54.997712)"
                                                            />
                                                            <path
                                                                fill="url(#8lwgqgx45z)"
                                                                d="M20 0c2.21 0 4 1.79 4 4v156.002h-8V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(128.000000, 54.997712)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(192.000000, 74.003416)"
                                                        >
                                                            <tspan x="5.214" y="155.997">
                                                                Za
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                fill="url(#h5rvnd62zA)"
                                                                d="M4 77.548c2.21 0 4 1.791 4 4v59.449H0V81.548c0-2.209 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(192.000000, 74.003416)"
                                                            />
                                                            <path
                                                                fill="url(#gzhdve13aB)"
                                                                d="M20 0c2.21 0 4 1.79 4 4v136.997h-8V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(192.000000, 74.003416)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(256.000000, 0.000000)"
                                                        >
                                                            <tspan x="5.534" y="230">
                                                                Zo
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                fill="url(#7er3k96rmC)"
                                                                d="M4 38.257c2.21 0 4 1.791 4 4V215H0V42.257c0-2.209 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(256.000000, 0.000000)"
                                                            />
                                                            <path
                                                                fill="url(#wm52epymrD)"
                                                                d="M20 0c2.21 0 4 1.79 4 4v211h-8V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(256.000000, 0.000000)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(320.000000, 18.999752)"
                                                        >
                                                            <tspan x="3.438" y="211">
                                                                Ma
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                fill="url(#gem5umpcvE)"
                                                                d="M4 75.385c2.21 0 4 1.79 4 4V196H0V79.385c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(320.000000, 18.999752)"
                                                            />
                                                            <path
                                                                fill="url(#uh0yewtboF)"
                                                                d="M20 0c2.21 0 4 1.79 4 4v192h-8V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(320.000000, 18.999752)"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <text
                                                            fill="#D7D9E3"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="12"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(384.000000, 61.999655)"
                                                        >
                                                            <tspan x="6.946" y="168">
                                                                Di
                                                            </tspan>
                                                        </text>
                                                        <g>
                                                            <path
                                                                fill="url(#8bhgomfzmG)"
                                                                d="M4 61.401c2.21 0 4 1.791 4 4v87.6H0V65.4c0-2.209 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(384.000000, 61.999655)"
                                                            />
                                                            <path
                                                                fill="url(#8o1mwdhu1H)"
                                                                d="M20 0c2.21 0 4 1.79 4 4v149h-8V4c0-2.21 1.79-4 4-4z"
                                                                transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(70.000000, 79.000000) translate(384.000000, 61.999655)"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g
                                                        fill="#5CD5E5"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(251.000000, 32.000000) translate(122.000000, 0.000000)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                Tijdig geleegd
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g
                                                        fill="#EF7B75"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(260.000000, 0.000000) translate(251.000000, 32.000000)"
                                                    >
                                                        <circle cx="34.5" cy="7.5" r="4.5" fillRule="nonzero" />
                                                        <text fontFamily="Lato-Regular, Lato" fontSize="12">
                                                            <tspan x="44" y="12">
                                                                Te laat geleegd
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <g transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000)">
                                                        <use
                                                            fill="#000"
                                                            filter="url(#scbge16vsI)"
                                                            xlinkHref="#5n56b9iuuJ"
                                                        />
                                                        <use fill="#FFF" fillOpacity=".85" xlinkHref="#5n56b9iuuJ" />
                                                    </g>
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Futura-Medium, Futura"
                                                        fontSize="18"
                                                        fontWeight="400"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000)"
                                                    >
                                                        <tspan x="30" y="44">
                                                            Live capaciteit
                                                        </tspan>
                                                    </text>
                                                    <g>
                                                        <path
                                                            d="M21.339 3.661c.488.488.488 1.28 0 1.768L14.268 12.5l7.07 7.071c.489.488.489 1.28 0 1.768-.487.488-1.279.488-1.767 0L12.5 14.268l-7.071 7.07c-.488.489-1.28.489-1.768 0-.488-.487-.488-1.279 0-1.767l7.071-7.071-7.07-7.071c-.489-.488-.489-1.28 0-1.768.487-.488 1.279-.488 1.767 0l7.071 7.071 7.071-7.07c.488-.489 1.28-.489 1.768 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(200.000000, 25.000000)"
                                                        />
                                                    </g>
                                                </g>
                                                <g>
                                                    <path
                                                        fill="#D7D9E3"
                                                        d="M22 0H23V126H22z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <path
                                                        fill="#D6F5F9"
                                                        d="M161 17v6c0 .552-.311 1-.695 1H22.695c-.384 0-.695-.448-.695-1v-6c0-.552.311-1 .695-1h137.61c.384 0 .695.448.695 1z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <path
                                                        fill="url(#ie60zimg3K)"
                                                        d="M124 17v6c0 .552-.691 1-1.543 1H23.543C22.691 24 22 23.552 22 23v-6c0-.552.691-1 1.543-1h98.914c.852 0 1.543.448 1.543 1z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Lato-Regular, Lato"
                                                        fontSize="12"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    >
                                                        <tspan x="166.648" y="24">
                                                            78%
                                                        </tspan>
                                                    </text>
                                                    <g fill="#8CE9AE" fillRule="nonzero">
                                                        <path
                                                            d="M11.143 5.281C10.05 6.576 9.37 7.883 9.101 9.202c-.252 1.236.065 2.33.951 3.281.097.104.107.257.027.371l-.016.021-.051.063c-.748.93-1.054 1.964-.917 3.104.044.375.277.755.697 1.139l.05.044c.209.185.461.371.757.558l.072.045.073.045.075.045.077.045.078.045.08.045.041.023.083.045.042.022.085.046.043.022.088.045.155.079.16.078.165.078.084.04c-1.122 1.486-2.92 1.83-5.394 1.034l-.162-.053-.247-.087c-.136-.049-.286-.052-.423-.01l-.038.013-.083.03c-2.563.938-4.448.658-5.653-.837 2.412-1.001 3.62-2.276 3.62-3.825.001-.218-.04-.483-.122-.795l-.03-.112c-.016-.057-.034-.116-.053-.175l-.039-.122-.02-.063-.045-.127-.047-.132-.025-.067-.053-.137-.056-.142-.06-.145-.03-.074-.066-.151-.068-.155-.071-.16-.075-.162-.039-.083-.08-.168-.04-.086c-.04-.083-.04-.178.001-.26l.014-.024.046-.074.045-.075.088-.146.084-.144.022-.04.045-.079.021-.039.043-.078.041-.076.02-.038.04-.076.074-.148.035-.073.034-.072.033-.07.032-.071.015-.035.03-.069.041-.101c.27-.67.343-1.208.221-1.612-.08-.266-.262-.58-.546-.94l-.077-.095c-.04-.048-.08-.097-.124-.147l-.088-.1-.046-.052-.095-.103-.05-.053-.101-.107-.107-.11-.055-.055-.114-.112-.118-.115-.061-.058-.126-.118-.13-.12-.067-.06-.137-.124-.141-.125-.147-.128-.074-.065-.154-.13L.91 5.29c1.257-.714 2.891-.548 4.904.5.093.048.204.05.297.005l.025-.014.077-.045c1.98-1.148 3.623-1.3 4.93-.455zM9.912 0c.191 0 .346.154.346.345 0 .19-.155.345-.347.345-1.172 0-2.037.5-2.636 1.524-.046.079-.09.167-.135.267l-.039.087c-.038.09-.075.189-.113.295l-.037.108-.037.114-.036.118-.018.062-.037.126-.018.066-.035.134-.036.14-.035.144-.035.15-.035.155-.035.16-.034.166-.034.17-.034.177-.034.18-.033.187-.016.095-.221.13c-.096.055-.215.058-.314.007l-.164-.085.05-.291.033-.189.034-.183.034-.179.034-.174.035-.169.035-.164.036-.159.036-.154.036-.15.018-.072.037-.142.018-.069.038-.135.037-.13.038-.124.038-.12.039-.115.02-.056.038-.107c.04-.106.079-.203.12-.294l.04-.088c.04-.086.081-.164.123-.235C7.4.632 8.492 0 9.912 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000) translate(1.000000, 10.000000)"
                                                        />
                                                    </g>
                                                    <path
                                                        fill="#D6F5F9"
                                                        fillOpacity=".8"
                                                        d="M161 60v6c0 .552-.311 1-.695 1H22.695c-.384 0-.695-.448-.695-1v-6c0-.552.311-1 .695-1h137.61c.384 0 .695.448.695 1z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <path
                                                        fill="url(#u11g34bp5L)"
                                                        d="M53 60v6c0 .552-.21 1-.47 1H22.47c-.26 0-.47-.448-.47-1v-6c0-.552.21-1 .47-1h30.06c.26 0 .47.448.47 1z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Lato-Regular, Lato"
                                                        fontSize="12"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    >
                                                        <tspan x="166.648" y="67">
                                                            19%
                                                        </tspan>
                                                    </text>
                                                    <g fill="#5CD5E5">
                                                        <path
                                                            d="M6.253 2.216v.292c.003.016.016.033.038.055l.025.024.03.027.035.03.04.033.094.076.41.323.15.121.16.13.123.103.083.071.127.11.085.076.085.078.085.079.085.08.042.041.084.083.083.085.04.042.082.086.079.088.03.034c.43.49.768 1.029.819 1.559.029.305.009.656-.043 1.037l-.014.1-.013.085-.028.172-.018.104-.03.16L9 7.707l-.042.202-.022.102-.045.206-.048.208-.1.424-.154.647-.05.217-.026.108-.05.217-.023.109-.047.216-.044.216-.015.077c-.12.617-.205 1.217-.206 1.762 0 .68.122 1.287.283 1.859l.037.13.04.13.031.105.062.193.03.096.155.474.03.095.059.188.036.121c.174.587.302 1.176.277 1.818-.027.692-.188 1.249-.485 1.67-.304.432-.872.7-1.488.7L1.97 20H1.94c-.604-.009-1.158-.273-1.456-.698-.295-.42-.456-.977-.481-1.668-.026-.687.124-1.313.317-1.94l.06-.189.06-.189.125-.38.062-.192.016-.05.04-.128.039-.128.03-.103.022-.078.022-.079.028-.105.015-.059c.126-.493.214-1.016.215-1.591 0-.568-.09-1.195-.218-1.84l-.043-.215-.046-.216-.024-.108-.049-.217-.05-.217-.228-.968-.05-.21-.046-.21-.045-.206-.022-.102-.041-.201-.021-.107-.02-.107-.019-.105-.013-.078-.036-.23-.014-.1-.008-.058c-.045-.36-.06-.69-.033-.98.053-.539.402-1.087.843-1.583l.051-.057.026-.029.055-.06.112-.116.043-.043.085-.085.087-.083.087-.082.087-.08.087-.078.087-.077.086-.074.085-.073.084-.07.123-.102.158-.128.184-.145.305-.24.072-.058.041-.034.037-.032.031-.027.025-.025.01-.01c.017-.017.027-.032.03-.042v-.297l3.304-.002zM6.163 0c.248.007.446.17.446.37l-.001 1.235v.014c-.01.198-.215.357-.465.357l-3.132.002h-.017c-.249-.008-.447-.17-.447-.37L2.548.373V.359c.01-.198.214-.357.465-.357L6.145 0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000) translate(3.000000, 53.000000)"
                                                        />
                                                    </g>
                                                    <path
                                                        fill="#D6F5F9"
                                                        fillOpacity=".8"
                                                        d="M161 103v6c0 .552-.311 1-.695 1H22.695c-.384 0-.695-.448-.695-1v-6c0-.552.311-1 .695-1h137.61c.384 0 .695.448.695 1z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <path
                                                        fill="url(#xexbhvbj2M)"
                                                        d="M76 103v6c0 .552-.366 1-.817 1H22.817c-.451 0-.817-.448-.817-1v-6c0-.552.366-1 .817-1h52.366c.451 0 .817.448.817 1z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                    <text
                                                        fill="#0B6C73"
                                                        fontFamily="Lato-Regular, Lato"
                                                        fontSize="12"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    >
                                                        <tspan x="166.648" y="110">
                                                            35%
                                                        </tspan>
                                                    </text>
                                                    <path
                                                        fill="#0B6C73"
                                                        d="M8.005 96l.287.003.164.003.21.004.2.005.19.007.134.005.13.006.162.01.115.007.074.005.105.009.1.009.094.01.059.007.057.007.013.002.092.013.049.008.046.008.043.009.041.009v3.592c.006.074.054.134.118.156l.051.008h3.43c.026-.022.047-.014.065.023l.018.045.007.024.007.026.003.014.007.031.006.034.006.037.006.04.006.042.005.046.006.049.007.078.01.115.01.127.005.102.006.11.006.116.005.123.006.174.006.185.006.25.006.322.004.35.002.376.001.473v.289l-.002.152-.002.155-.002.16-.003.165-.005.255-.006.265-.007.274-.006.189-.01.291-.013.404-.017.42-.018.44-.02.456-.028.595-.019.37-.026.51-.036.66-.03.55-.033.565-.035.584-.037.601-.039.62-.052.798-.065.994-.059.859-.062.886c-.005.073-.052.134-.116.155l-.05.009L.17 116H.158c-.07-.005-.129-.055-.15-.122L0 115.824V97.1c.007-.08.065-.148.142-.16l.056-.01.055-.01.436-.072.53-.087.41-.066.3-.048.391-.06.286-.044.279-.042.272-.04.267-.039.345-.049.251-.034.325-.044.237-.03.305-.039.221-.026.144-.017.142-.016.206-.023.134-.015.132-.013.191-.019.185-.017.179-.016.172-.013.112-.009.161-.01.104-.007.102-.006.098-.004.143-.006.091-.003.132-.003L7.62 96h.385zm2.804 14.83H3.093l-.013.001c-.073.012-.128.066-.128.13 0 .055.04.103.097.122l.046.01h7.729l.014-.002c.072-.012.127-.065.127-.13 0-.073-.07-.132-.156-.132zm0-1.85h-7.7l-.016.001-.013.002c-.073.011-.128.065-.128.13 0 .055.04.102.097.122l.046.01h7.729l.014-.002c.072-.012.127-.066.127-.13 0-.073-.07-.132-.156-.132zm0-1.847H3.093l-.013.002c-.073.011-.128.065-.128.13 0 .055.04.102.097.122l.046.01h7.714l.015-.001.014-.002c.072-.011.127-.065.127-.13 0-.072-.07-.131-.156-.131zm-.267-10.988l3.428 3.578h-3.272c-.088-.007-.156-.084-.156-.177v-3.4z"
                                                        transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 179.000000)"
                                                    />
                                                </g>
                                                <g>
                                                    <g>
                                                        <text
                                                            fill="#0B6B73"
                                                            fontFamily="Futura-Medium, Futura"
                                                            fontSize="36"
                                                            fontWeight="400"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000)"
                                                        >
                                                            <tspan x="0" y="37">
                                                                214
                                                            </tspan>
                                                        </text>
                                                        <text
                                                            fill="#0B6C73"
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000)"
                                                        >
                                                            <tspan x="69" y="37">
                                                                IQ Bins
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g fill="#5CD5E5">
                                                        <path
                                                            d="M0 0H65.735V3H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000) translate(0.000000, 54.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000) translate(0.000000, 54.000000)"
                                                        >
                                                            <tspan x="0" y="23">
                                                                85
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g fill="#F4DE77">
                                                        <path
                                                            fillRule="nonzero"
                                                            d="M0 0H54.929V3H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000) translate(64.834123, 54.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000) translate(64.834123, 54.000000)"
                                                        >
                                                            <tspan x=".06" y="23">
                                                                38
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                    <g fill="#EF7B75">
                                                        <path
                                                            fillRule="nonzero"
                                                            d="M0 0H71.137V3H0z"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000) translate(118.862559, 54.000000)"
                                                        />
                                                        <text
                                                            fontFamily="Lato-Regular, Lato"
                                                            fontSize="18"
                                                            transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000) translate(30.000000, 70.000000) translate(118.862559, 54.000000)"
                                                        >
                                                            <tspan x="0" y="23">
                                                                91
                                                            </tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                                <text
                                                    fill="#0B6C73"
                                                    fontFamily="Lato-Regular, Lato"
                                                    fontSize="12"
                                                    transform="translate(-26.000000, -88.000000) translate(30.000000, 90.000000)"
                                                >
                                                    <tspan x="189.136" y="44">
                                                        15:45
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </Suspense>
            )}
        />
    )
}
