import React from 'react'
import classNames, { Argument } from 'classnames'
import { Image } from '../Image/Image'

import styles from './IQBinLogo.module.scss'

interface Props {
    className?: Argument
}

export const IQBinLogo: React.FunctionComponent<Props> = ({ className }) => {
    return <Image fileName={'logo.png'} alt={'IQBin Logo'} className={classNames(styles.container, className)} />
}
