import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { routes } from '../../../routes'
import { useUser } from '../../Auth/services/hooks'
import { Login } from '../components/Login/Login'

export interface Props
    extends RouteComponentProps<Record<string, never>, Record<string, unknown>, { force: boolean }> {}

export const LoginView: FunctionComponent<Props> = params => {
    return params.location.state?.force ? <Login /> : <LoginRedirect />
}

const LoginRedirect: FunctionComponent = () => {
    const user = useUser()
    const history = useHistory()

    useEffect(() => {
        if (user) {
            history.push(routes.index)
        }
    }, [user, history])

    return user === null ? <Login /> : <Fragment />
}
