import React, { Fragment, FunctionComponent, useContext, useMemo, useState } from 'react'

import styles from './DetailsView.module.scss'
import { Auth } from '../../../Auth/components/Auth/Auth'
import { PageLayout } from '../../../Core/components/Layout/PageLayout/PageLayout'

import { useGetAreasView, useGetDataView } from '../../services/hooks'
import { Areas } from '../../components/Areas/Areas'
import { RouteComponentProps, useHistory } from 'react-router'
import useSharedState from '../../components/State/State'
import { routes } from '../../../../routes'
import { Icon, Icons } from '../../../Core/components/Icon/Icon'
import { TextButton } from '../../../Core/components/Button/TextButton/TextButton'
import { translations } from '../../../../translations'
import { CompareGraph } from '../../components/Comparer/CompareGraph'
import { Kpi } from '../../services/types'
import mapping from '../../components/Mapping/Mapping'
import locale from '../../../Core/utils/locale'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../Core/components/ActivityIndicator/ActicvityIndicator'
import { pathof } from '../../../../libs/helpers/helpers'
import { PageError } from '../../../Core/components/PageError/PageError'
import useWindowDimensions from '../../../Core/utils/useWindowDimensions'
import { LocaleContext } from '../../../Providers/LocaleProvider'

export interface DetailsParam {
    areaId: string
    placeId: string
}

export const DetailsView: FunctionComponent<RouteComponentProps<DetailsParam>> = param => {
    return (
        <Auth>
            <DatailsViewLayout {...param.match.params} />
        </Auth>
    )
}

const DatailsViewLayout: FunctionComponent<DetailsParam> = ({ areaId, placeId }) => {
    const history = useHistory()

    const localeContext = useContext(LocaleContext)

    const [placeKey, setPlaceKey] = useState<{ areaId: string; placeId: string }>({ areaId, placeId })

    const state = useSharedState()

    const areasResult = useGetAreasView(state.latestKpy)

    const addDays = (date: Date, days: number) => {
        date.setDate(date.getDate() + days)
        return date
    }

    const selected = useMemo(() => {
        if (areasResult.data) {
            const area = areasResult.data.areas?.find(a => a.id == placeKey?.areaId)
            if (area) {
                const place = area.places?.find(p => p.id === placeKey?.placeId)
                if (place) {
                    return {
                        area,
                        place,
                        ids: place.iqBins?.map(b => b.id),
                        startDate: addDays(new Date(), -7),
                        endDate: new Date(),
                    }
                }
            }

            return null
        }

        return undefined
    }, [areasResult.data, placeKey])

    const onPlaeKeyChnage = (key: { areaId: string; placeId: string }) => {
        if (key.areaId !== placeKey.areaId && key.placeId !== placeKey.placeId) {
            setPlaceKey(key)
            history.replace(routes.details(key.areaId, key.placeId))
        }
    }
    const onSetLatestKpi = (kpi?: Kpi) => {
        state.setLatestKpi(kpi)
        history.push(routes.overview)
    }

    const dataResult = useGetDataView(selected?.ids, selected?.startDate, selected?.endDate)

    if (areasResult.error || selected === null || dataResult.error) {
        throw new PageError(areasResult.error)
    }

    const wd = useWindowDimensions()

    return (
        <PageLayout
            loading={areasResult.loading}
            content={() => (
                <div className={styles.container}>
                    <div className={styles.content}>
                        {areasResult.data && (
                            <Areas
                                data={areasResult.data}
                                kpi={state.latestKpy}
                                onKpiChanged={onSetLatestKpi}
                                onPlaceKeyChange={onPlaeKeyChnage}
                                placeKey={placeKey}
                            />
                        )}
                        <div className={styles.explorer}>
                            <div className={styles.header}>
                                {selected && (
                                    <span>{`${selected.area.name} > ${selected.place.name} | ${
                                        selected.place.iqBins?.length || 0
                                    } ${localeContext._(
                                        pathof<typeof translations>()._('areas')._('bins').path
                                    )}`}</span>
                                )}
                                <TextButton icon={Icons.close} onClick={() => history.push(routes.overview)} />
                            </div>
                            {dataResult.loading || !dataResult.data ? (
                                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                            ) : (
                                <Fragment>
                                    <div className={styles.info}>
                                        {dataResult.data?.map((d, i) => {
                                            return (
                                                <div key={`ti_${i}`}>
                                                    <span
                                                        style={{ borderColor: mapping.getColor(d.type) || '#d7d9e3' }}
                                                    >
                                                        <Icon icon={mapping.largeIconsMap.get(d.type) || Icons.waste} />
                                                    </span>
                                                    <span>
                                                        <span>{`${new Intl.NumberFormat(undefined, {
                                                            maximumFractionDigits: 0,
                                                        }).format(
                                                            d.points?.[d.points?.length - 1]?.value || 0
                                                        )}%`}</span>
                                                        <span>
                                                            {localeContext._(
                                                                pathof<typeof translations>()._('time').path,
                                                                {
                                                                    time: locale.format('%e %b %H:%M')(
                                                                        d.points?.[d.points?.length - 1]?.time
                                                                            ? new Date(
                                                                                  d.points?.[d.points?.length - 1]?.time
                                                                              )
                                                                            : new Date()
                                                                    ),
                                                                }
                                                            )}
                                                        </span>
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <CompareGraph
                                        data={dataResult.data}
                                        width={wd.width - 525}
                                        height={wd.height - 310}
                                    />
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )}
        />
    )
}
