import React from 'react'
import classNames, { Argument } from 'classnames'

import styles from './PageLayout.module.scss'
import { ActivityIndicator, ActivityIndicatorEnumType } from '../../ActivityIndicator/ActicvityIndicator'

interface Props {
    className?: Argument
    content: () => JSX.Element
    loading?: boolean
}

export const PageLayout: React.FunctionComponent<Props> = ({ className, content, loading }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={classNames(styles.wrapper)}>
                {loading ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                ) : (
                    content()
                )}
            </div>
        </div>
    )
}
