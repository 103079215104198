import React, { Fragment, FunctionComponent, Suspense, useEffect, useState } from 'react'

import classNames, { Argument } from 'classnames'

import styles from './Image.module.scss'
import { ActivityIndicator } from '../ActivityIndicator/ActicvityIndicator'

export interface Props {
    className?: Argument
    alt: string
    fileName: string
    size?: [number, number]
}

type SupportedType = 'webp' | 'main'
const SupportedTypes: SupportedType[] = ['webp']

export const Image: FunctionComponent<Props> = ({ className, fileName, alt, size }) => {
    //eslint-disable-next-line
    const [images, setImages] = useState<Map<SupportedType, any>>()

    useEffect(() => {
        ;(async function loadImages() {
            const parts: string[] = fileName.split('.')

            const tasks = SupportedTypes.map(ex =>
                import(`../../../../assets/visuals/${ex}/${parts[0]}.${ex}`).then(image => {
                    return { ex, image: image.default }
                })
            ).concat(
                //eslint-disable-next-line
                import(`../../../../assets/visuals/${fileName}`).then<{ ex: SupportedType; image: any }>(image => {
                    return { ex: 'main', image: image.default }
                })
            )

            const result = await Promise.all(tasks)

            const images = new Map()

            result.forEach(image => {
                images.set(image.ex, image.image)
            })

            setImages(images)
        })()
        //eslint-disable-next-line
    }, [fileName])

    return (
        <Suspense fallback={<ActivityIndicator />}>
            <picture
                className={classNames(styles.container, className)}
                style={{ width: size?.[0], height: size?.[1] }}
            >
                {images && (
                    <Fragment>
                        <source type="image/webp" srcSet={images.get('webp')} />
                        <img src={images.get('main')} alt={alt} />
                    </Fragment>
                )}
            </picture>
        </Suspense>
    )
}
