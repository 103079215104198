import React, { FunctionComponent, Suspense } from 'react'

import styles from './HeatView.module.scss'
import { Auth } from '../../../Auth/components/Auth/Auth'
import { PageLayout } from '../../../Core/components/Layout/PageLayout/PageLayout'
import { Panel } from '../../../Core/components/Panel/Panel'
import { useHistory } from 'react-router'
import { routes } from '../../../../routes'
import { useGetAreasView } from '../../services/hooks'
import { HeatAreas } from '../../components/HeatAreas/HeatAreas'
import { PageError } from '../../../Core/components/PageError/PageError'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../Core/components/ActivityIndicator/ActicvityIndicator'

export const HeatView: FunctionComponent = () => {
    return (
        <Auth>
            <HeatViewLayout />
        </Auth>
    )
}

const HeatViewLayout: FunctionComponent = () => {
    const history = useHistory()

    const result = useGetAreasView()

    if (result.error) {
        throw new PageError(result.error)
    }

    return (
        <PageLayout
            loading={result.loading}
            content={() => (
                <Suspense fallback={<ActivityIndicator size={ActivityIndicatorEnumType.large} />}>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            {result.data && <HeatAreas data={result.data} />}
                            <Panel onLayersClick={() => history.push(routes.overview)} />
                        </div>
                    </div>
                </Suspense>
            )}
        />
    )
}
