import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { config } from './config'
import { routes } from './routes'
import { OverviewView } from './domain/Overview/Views/OverviewView/OverviewView'
import { LoginView } from './domain/Login/Views/LoginView'
import { ResetPassword } from './domain/Login/components/ResetPassword/ResetPassword'
import { GlobalErrorView } from './domain/Errors/Views/GlobalErrorView'
import { DetailsView } from './domain/Overview/Views/DetailsView/DetailsView'
import { PlannerView } from './domain/Planner/Views/PlannerView/PlannerView'
import { HeatView } from './domain/Overview/Views/PlannerView/HeatView'
import { AnalyseView } from './domain/Analyse/Views/AnalyaseView/AnalyseView'
import { RedirectorTemplate } from './domain/Generic/components/RedirectorTemplate'
import { withLogin } from './domain/Auth/services/service'
import { TelemetryProvider } from './domain/Telemetry/components/TelemetryProvider'
import { Shield } from './domain/Errors/Shield'
import useScroll from './domain/Core/utils/useScroll'
import useResize from './domain/Core/utils/useResize'

withLogin()

export const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={() => {
                const path = config.defaultPath

                return <Redirect to={path} />
            }}
        />
    )
}

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useScroll()

    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route exact={true} path={routes.login} component={LoginView} />
                    <Route exact={true} path={routes.resetPassword} component={ResetPassword} />
                    <Route exact={true} path={routes.index} component={Redirector} />
                    <Route exact={true} path={routes.overview} component={OverviewView} />
                    <Route exact={true} path={routes.heat} component={HeatView} />
                    <Route exact={true} path={routes.details()} component={DetailsView} />
                    <Route exact={true} path={routes.planner} component={PlannerView} />
                    <Route exact={true} path={routes.analyse} component={AnalyseView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
