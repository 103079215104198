import styles from './ErrorLayout.module.scss'

import React, { useContext } from 'react'
import classNames, { Argument } from 'classnames'

import Title, { FontSize, HeadingLevel } from '../../Typography/Title'
import { LocaleContext } from '../../../../Providers/LocaleProvider'

interface Props {
    className?: Argument
    renderButton?: () => JSX.Element
    showFooter?: boolean
    logo: JSX.Element
}

export const ErrorLayout: React.FunctionComponent<Props> = props => {
    const locale = useContext(LocaleContext)

    return (
        <div
            className={classNames(styles.container, props.className, {
                [styles.showFooter]: !!props.showFooter,
            })}
        >
            <div className={styles.content}>
                {props.logo}
                <Title className={styles.title} headingLevel={HeadingLevel.h1} fontSize={FontSize.size44}>
                    {locale._('ErrorLayout.title')}
                </Title>
                {props.renderButton && <div className={styles.buttonWrapper}>{props.renderButton()}</div>}
            </div>
        </div>
    )
}
