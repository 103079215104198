import { config } from '../../../config'
import { client } from '../../Client/services/client'
import { ResponsePromise } from '../../Client/services/request'
import { AreasView, DataView, Kpi } from './types'

export interface IqbinService {
    getAreasView: (kpi?: Kpi) => ResponsePromise<AreasView>
    getDataView: (id: string, startDate: Date, endDate: Date) => ResponsePromise<DataView>
}

export const iqbinService: IqbinService = {
    getAreasView(kpi?: Kpi) {
        return client.get<AreasView>(config.views.areas, { params: { kpiType: kpi } })
    },

    getDataView(id: string, startDate: Date, endDate: Date) {
        return client.get(config.views.data(id), {
            params: {
                'filter.startDate': startDate,
                'filter.endDate': endDate,
            },
        })
    },
}
