import React, { FunctionComponent } from 'react'
import styles from './Header.module.scss'
import classNames, { Argument } from 'classnames'

import { Link } from 'react-router-dom'
import { Menu } from '../Menu/Menu'
import { IQBinLogo } from '../Logo/IQBinLogo'
import { config } from '../../../../config'

export interface HeaderParam {
    className?: Argument
    onLogout?: () => void
}

export const Header: FunctionComponent<HeaderParam> = ({ className, onLogout }) => {
    return (
        <div className={classNames(styles.authWrapper, className)}>
            <Link to={config.defaultPath} className={styles.logo}>
                <IQBinLogo />
            </Link>
            <Menu onLogout={onLogout} />
        </div>
    )
}
