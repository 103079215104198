import classNames, { Argument } from 'classnames'
import React, { FunctionComponent, useContext, useState } from 'react'
import { pathof } from '../../../../libs/helpers/helpers'
import { translations } from '../../../../translations'
import { TextButton } from '../../../Core/components/Button/TextButton/TextButton'
import { Icon, Icons } from '../../../Core/components/Icon/Icon'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import { AreasView, Kpi } from '../../services/types'
import mapping from '../Mapping/Mapping'

import styles from './Areas.module.scss'

export interface AreasParam {
    className?: Argument
    data: AreasView
    kpi?: Kpi
    onKpiChanged?: (kpi?: Kpi) => void
    placeKey?: { areaId: string; placeId: string }
    onPlaceKeyChange?: (key: { areaId: string; placeId: string }) => void
}

export const Areas: FunctionComponent<AreasParam> = ({
    data,
    className,
    kpi,
    onKpiChanged,
    placeKey,
    onPlaceKeyChange,
}) => {
    const locale = useContext(LocaleContext)

    const [expanded, setExpanded] = useState<string>(placeKey?.areaId || data.areas?.[0]?.id)

    return (
        <div className={classNames(styles.overview, className)}>
            <div className={styles.nav}>
                {[undefined, Kpi.Sufficient, Kpi.Moderate, Kpi.InSufficient].map((k, i) => {
                    return (
                        <TextButton
                            key={`nav_${i}`}
                            onClick={() => onKpiChanged && onKpiChanged(k)}
                            className={classNames(styles.navItem, {
                                [styles.isAll]: k === undefined,
                                [styles.isSufficient]: k === Kpi.InSufficient,
                                [styles.isModerate]: k === Kpi.Moderate,
                                [styles.isInSufficient]: k === Kpi.InSufficient,
                                [styles.isSelected]: kpi === k,
                            })}
                        >
                            {locale._(
                                `${pathof<typeof translations>()._('areas')._('nav').path}.${
                                    k ? k.toLowerCase() : 'all'
                                }`
                            )}
                        </TextButton>
                    )
                })}
            </div>
            <div className={styles.total}>
                <span>{data.total}</span>
                <span> {locale._(pathof<typeof translations>()._('areas')._('bins').path)}</span>
            </div>
            <div className={styles.area}>
                <div>
                    <span>
                        {[Kpi.Sufficient, Kpi.Moderate, Kpi.InSufficient].map((k, i) => {
                            return (
                                <span
                                    style={{
                                        width: `${Math.min(
                                            80,
                                            Math.max(10, ((data.counts[k] || 0) / data.total) * 100)
                                        )}%`,
                                    }}
                                    className={classNames({
                                        [styles.isSufficient]: k === Kpi.Sufficient,
                                        [styles.isModerate]: k === Kpi.Moderate,
                                        [styles.isInSufficient]: k === Kpi.InSufficient,
                                    })}
                                    key={`t_${i}`}
                                >
                                    {data.counts[k] || 0}
                                </span>
                            )
                        })}
                    </span>
                    <Icon icon={Icons.binemp} size={[15, 25]} />
                    <Icon icon={Icons.binhalf} size={[15, 25]} />
                    <Icon icon={Icons.binfull} size={[15, 25]} />
                </div>
            </div>
            {data.areas.map((a, i) => {
                return (
                    <div key={`a_${i}`} className={styles.area} onClick={() => setExpanded(a.id)}>
                        <div>
                            <span>{a.name}</span>
                            {[Kpi.Sufficient, Kpi.Moderate, Kpi.InSufficient].map((k, j) => {
                                return (
                                    <span
                                        className={classNames({
                                            [styles.isSufficient]: k === Kpi.Sufficient,
                                            [styles.isModerate]: k === Kpi.Moderate,
                                            [styles.isInSufficient]: k === Kpi.InSufficient,
                                        })}
                                        key={`a_${i}_c_${j}`}
                                    >
                                        {a.counts[k] || 0}
                                    </span>
                                )
                            })}
                        </div>
                        <div className={classNames({ [styles.show]: a.id === expanded })}>
                            {a.places.map((p, j) => {
                                return (
                                    <div
                                        key={`a_p_${i}_${j}`}
                                        className={classNames({ [styles.isSelected]: p.id === placeKey?.placeId })}
                                        onClick={e => {
                                            e.stopPropagation()
                                            onPlaceKeyChange && onPlaceKeyChange({ areaId: a.id, placeId: p.id })
                                        }}
                                    >
                                        <span>{p.name}</span>
                                        {p.iqBins.map((b, k) => {
                                            return (
                                                <span
                                                    className={classNames({
                                                        [styles.isSufficient]: b.latestKpi.kpi === Kpi.Sufficient,
                                                        [styles.isModerate]: b.latestKpi.kpi === Kpi.Moderate,
                                                        [styles.isInSufficient]: b.latestKpi.kpi === Kpi.InSufficient,
                                                    })}
                                                    key={`a_p_b_${i}_${j}_${k}`}
                                                >
                                                    <Icon icon={mapping.smallIconsMap.get(b.type) || Icons.waste} />
                                                    {`${Math.trunc(b.latestKpi.value)}%`}
                                                </span>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
