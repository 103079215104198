import { Icons, IconType } from '../../../Core/components/Icon/Icon'
import { WasteType } from '../../services/types'

const smallIconsMap = new Map<WasteType, IconType>()
smallIconsMap.set('rest', Icons.waste)
smallIconsMap.set('pmd', Icons.plastic)
smallIconsMap.set('paper', Icons.paper)

const largeIconsMap = new Map<WasteType, IconType>()
largeIconsMap.set('rest', Icons.wastelarge)
largeIconsMap.set('pmd', Icons.plasticlarge)
largeIconsMap.set('paper', Icons.paperlarge)

const colorsMap = new Map<WasteType, string>()
colorsMap.set('rest', '#8ce9ae')
colorsMap.set('pmd', '#5cd5e5')
colorsMap.set('paper', '#0b6c73')

const mapping = {
    smallIconsMap,
    largeIconsMap,
    getColor(type: WasteType) {
        return colorsMap.get(type) || '#d7d9e3'
    },
}

export default mapping
