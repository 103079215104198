import styles from './Title.module.scss'

import React from 'react'

import classNames, { Argument } from 'classnames'

export enum HeadingLevel {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
}

export enum FontSize {
    size14 = 'SIZE14',
    size18 = 'SIZE18',
    size24 = 'SIZE24',
    size44 = 'SIZE44',
}

interface Props {
    className?: Argument
    headingLevel: HeadingLevel
    fontSize?: FontSize
}

export default class Title extends React.Component<Props> {
    public render() {
        const { children, headingLevel } = this.props

        const Heading = headingLevel as React.ElementType

        return <Heading className={this.getClassNames()}>{children}</Heading>
    }

    private getClassNames() {
        const { className, fontSize } = this.props

        return classNames(styles.container, className, {
            [styles.size14]: fontSize === FontSize.size14,
            [styles.size18]: fontSize === FontSize.size18,
            [styles.size24]: fontSize === FontSize.size24,
            [styles.size44]: fontSize === FontSize.size44,
        })
    }
}
