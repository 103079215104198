import React, { Fragment, FunctionComponent, useContext, useState } from 'react'

import classNames from 'classnames'

import { TextButton } from '../Button/TextButton/TextButton'
import { Icon, Icons } from '../Icon/Icon'
import styles from './Menu.module.scss'
import { Link, matchPath, useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import useOutsideAlerter from '../../utils/useOutsideAlerter'
import { pathof } from '../../../../libs/helpers/helpers'

export interface Param {
    onLogout?: () => void
}

export const Menu: FunctionComponent<Param> = ({ onLogout }) => {
    const [isVisiable, setIsVisiable] = useState(false)

    const locale = useContext(LocaleContext)
    const menuRef = useOutsideAlerter<HTMLDivElement, 'click'>('click', () => setIsVisiable(false))

    const history = useHistory()

    const isActive = (path: string) => {
        return matchPath(history.location.pathname, path)
    }

    return (
        <Fragment>
            <div className={styles.nav}>
                <Link
                    className={classNames(styles.navItem, {
                        [styles.isActive]:
                            isActive(routes.overview) || isActive(routes.details()) || isActive(routes.heat),
                    })}
                    to={routes.overview}
                >
                    {locale._(pathof<typeof translations>()._('menu')._('overview').path)}
                </Link>
                <Link
                    className={classNames(styles.navItem, {
                        [styles.isActive]: isActive(routes.planner),
                    })}
                    to={routes.planner}
                >
                    {locale._(pathof<typeof translations>()._('menu')._('planner').path)}
                </Link>
                <Link
                    className={classNames(styles.navItem, {
                        [styles.isActive]: isActive(routes.analyse),
                    })}
                    to={routes.analyse}
                >
                    {locale._(pathof<typeof translations>()._('menu')._('analyse').path)}
                </Link>
            </div>
            <Icon icon={Icons.bell} className={styles.bell} />
            <div ref={menuRef} className={styles.menuWrapper}>
                <TextButton onClick={() => setIsVisiable(!isVisiable)}>
                    <Icon icon={Icons.menu} />
                </TextButton>
                <div
                    className={classNames(styles.dropdownContent, {
                        [styles.isVisiable]: isVisiable,
                    })}
                >
                    <span onClick={onLogout}>
                        {' '}
                        {locale._(pathof<typeof translations>()._('menu')._('logout').path)}
                    </span>
                </div>
            </div>
        </Fragment>
    )
}
