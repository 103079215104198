import React, { FunctionComponent, Suspense, useEffect, useState } from 'react'

import styles from './PlannerView.module.scss'
import { Auth } from '../../../Auth/components/Auth/Auth'
import { PageLayout } from '../../../Core/components/Layout/PageLayout/PageLayout'
import { config } from '../../../../config'
import { Panel } from '../../../Core/components/Panel/Panel'
import { Image } from '../../../Core/components/Image/Image'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../Core/components/ActivityIndicator/ActicvityIndicator'

export const PlannerView: FunctionComponent = () => {
    return (
        <Auth>
            <PlannerViewLayout />
        </Auth>
    )
}

const PlannerViewLayout: FunctionComponent = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), config.deley)
    }, [])

    return (
        <PageLayout
            loading={loading}
            content={() => (
                <Suspense fallback={<ActivityIndicator size={ActivityIndicatorEnumType.large} />}>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <Image fileName={'config.png'} alt={'Configurator'} className={styles.box} />
                            <Panel layers={false} />
                        </div>
                    </div>
                </Suspense>
            )}
        />
    )
}
