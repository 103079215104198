export enum Kpi {
    Sufficient = 'Sufficient',
    Moderate = 'Moderate',
    InSufficient = 'InSufficient',
    Unknown = 'Unknown',
}

export type Counts = {
    [key in keyof typeof Kpi]: number
}

export interface LatestValue {
    kpi: Kpi
    value: number
    time: string
}

export type WasteType = 'paper' | 'pmd' | 'rest' | string

export interface IqBin {
    id: string
    name: string
    identifier: string
    type: WasteType
    latestKpi: {
        kpi: Kpi
        value: number
        time: Date
    }
}

export interface Place {
    id: string
    name: string

    iqBins: IqBin[]
}

export interface Area {
    id: string
    name: string

    places: Place[]

    counts: Counts
}

export interface AreasView {
    areas: Area[]
    total: number
    counts: Counts
}

export interface DataPoint {
    time: string
    value: number
}

export interface DataView {
    type: WasteType
    points: DataPoint[]
}
