import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import { Argument } from 'classnames'
import { Header } from '../../../Core/components/Header/Header'
import { useUser } from '../../services/hooks'
import { routes } from '../../../../routes'
import { authService } from '../../services/service'
import { config } from '../../../../config'
import { IUser } from '../../services/types'

export const handleOnLogout = async (history: History<unknown>) => {
    try {
        await authService.getLogout()
    } catch {}

    history.push(config.defaultPath)
    history.push(routes.login, { force: true })
}

export interface AuthParam {
    className?: Argument
}

export const Auth: FunctionComponent<AuthParam> = ({ className, children }) => {
    const user = useUser()

    const history = useHistory()

    useEffect(() => {
        if (user === null) {
            history.push(routes.login, { force: true })
        }
    }, [user, history])

    return user ? (
        <Fragment>
            <Header className={className} onLogout={() => handleOnLogout(history)} />
            {children}
        </Fragment>
    ) : null
}
export interface AuthParams {
    content: (user: IUser, onLogout: () => void) => JSX.Element
}

// export const Auth: FunctionComponent<AuthParams> = ({ content }) => {
//     const user = useUser()

//     const history = useHistory()

//     useEffect(() => {
//         if (user === null) {
//             history.push(routes.login)
//         }
//     }, [user, history])

//     const handleOnLogout = async () => {
//         try {
//             await authService.getLogout()
//         } catch {}

//         history.push(config.defaultPath)
//         history.push(routes.login)
//     }

//     return user ? content(user, handleOnLogout) : null
// }
