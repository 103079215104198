import * as d3 from 'd3'

import locale from './locale'

const formatMillisecond = locale.format('%S.%L'),
    formatSecond = locale.format('%M:%S'),
    formatMinute = locale.format('%H:%M'),
    formatHour = locale.format('%H:%M'),
    formatDay = locale.format('%a %d'),
    formatWeek = locale.format('%b %d'),
    formatMonth = locale.format('%b %d'),
    formatYear = locale.format('%Y')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function d3Locale(date: any) {
    return (
        d3.timeSecond(date) < date
            ? formatMillisecond
            : d3.timeMinute(date) < date
            ? formatSecond
            : d3.timeHour(date) < date
            ? formatMinute
            : d3.timeDay(date) < date
            ? formatHour
            : d3.timeMonth(date) < date
            ? d3.timeWeek(date) < date
                ? formatDay
                : formatWeek
            : d3.timeYear(date) < date
            ? formatMonth
            : formatYear
    )(date)
}
