import React, { FunctionComponent, Fragment, useContext } from 'react'
import { XYPlot, XAxis, YAxis, LineSeries, HorizontalGridLines } from 'react-vis'
import '../../../../../node_modules/react-vis/dist/style.css'
import { pathof } from '../../../../libs/helpers/helpers'
import { translations } from '../../../../translations'
import d3Locale from '../../../Core/utils/d3Locale'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import { DataView } from '../../services/types'
import mapping from '../Mapping/Mapping'
import styles from './CompareGraph.module.scss'

interface GraphProp {
    data: DataView[]
    width?: number
    height?: number
}

const CompareGraphInternal: FunctionComponent<GraphProp> = ({ data, width = 720, height = 320 }) => {
    const locale = useContext(LocaleContext)

    return (
        <Fragment>
            <div className={styles.nav}>
                {data.map((d, i) => {
                    return (
                        <div key={`nav_${i}`}>
                            <span style={{ backgroundColor: mapping.getColor(d.type) }} />
                            <span style={{ color: mapping.getColor(d.type) }}>
                                {locale._(`${pathof<typeof translations>()._('graph').path}.${d.type}`)}
                            </span>
                        </div>
                    )
                })}
            </div>
            <XYPlot xType={'time'} width={width} height={height - 30} yDomain={[0, 100]}>
                <HorizontalGridLines style={{ stroke: '#d7d9e3', strokeWidth: 0.3 }} tickTotal={11} />
                <XAxis
                    style={{
                        fontSize: 12,
                        line: { stroke: '#d7d9e3', strokeWidth: 0.3 },
                        ticks: {
                            fill: '#d7d9e3',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                        },
                    }}
                    tickTotal={Math.trunc(width / 70)}
                    tickFormat={d3Locale}
                    hideLine={true}
                />
                <YAxis
                    style={{
                        fontSize: 12,
                        line: { stroke: '#d7d9e3', strokeWidth: 0.3 },
                        ticks: {
                            fill: '#d7d9e3',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                        },
                    }}
                    tickTotal={11}
                    tickFormat={t => `${new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(t)}%`}
                    hideLine={true}
                    marginLeft={42}
                />

                {data.map((item, i) => {
                    return (
                        <LineSeries
                            key={`ls_${i}`}
                            data={item.points.map(p => {
                                return { x: new Date(p.time).getTime(), y: p.value }
                            })}
                            color={mapping.getColor(item.type)}
                            curve={'curveMonotoneX'}
                            style={{
                                strokeWidth: 6,
                            }}
                        />
                    )
                })}
            </XYPlot>
        </Fragment>
    )
}

const CompareGraph = CompareGraphInternal

export { CompareGraph }
