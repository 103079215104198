export const routes = {
    index: '/',
    login: '/login',
    resetPassword: '/reset',
    overview: '/overview',
    heat: '/overview/heat',
    details: (areaId = ':areaId', placeId = ':placeId') => `/overview/details/${areaId}/${placeId}`,
    planner: '/planner',
    analyse: '/analyse',
}
