export interface IStorageService {
    get: <T>(key: string) => T | null
    set: <T>(key: string, item: T) => void
    remove: (key: string) => void
    clear: () => void
}

export const storageService: IStorageService = {
    get(key: string) {
        const item = localStorage.getItem(key)
        return item ? JSON.parse(item) : null
    },

    set<T>(key: string, item: T) {
        localStorage.setItem(key, JSON.stringify(item))
    },

    remove(key: string) {
        localStorage.removeItem(key)
    },

    clear() {
        localStorage.clear()
    },
}
