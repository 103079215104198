import classNames, { Argument } from 'classnames'

import React, { FunctionComponent } from 'react'
import styles from './TextButton.module.scss'
import { Icon, IconType } from '../../Icon/Icon'

interface Props {
    className?: Argument
    onClick?: () => void
    icon?: IconType
    isDisabled?: boolean
    style?: 'secondary'
}

export const TextButton: FunctionComponent<Props> = ({
    className,
    children,
    onClick,
    icon,
    style,
    isDisabled = false,
}) => {
    return (
        <button className={classNames(styles.wrapper, className)} onClick={onClick} disabled={isDisabled}>
            <span
                className={classNames(styles.text, {
                    [styles.isDisabled]: isDisabled,
                    [styles.isSecondary]: style === 'secondary',
                })}
            >
                {icon && <Icon icon={icon} className={styles.icon} />}
                {children}
            </span>
        </button>
    )
}
