import { TelemetryContext } from '../../Telemetry/components/TelemetryProvider'
import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { ParsedQuery, parse } from 'query-string'

export interface RedirectorProp extends RouteComponentProps {
    getRedirector: (params: ParsedQuery) => JSX.Element
}

export const RedirectorTemplate: FunctionComponent<RedirectorProp> = props => {
    return (
        <TelemetryContext.Consumer>
            {context => {
                const url = props.location.search,
                    params = parse(url)

                context.telemetryService?.trackEvent('Redirector', { path: url })

                const redirector = props.getRedirector(params)

                return redirector
            }}
        </TelemetryContext.Consumer>
    )
}
