import classNames, { Argument } from 'classnames'
import React, { FunctionComponent, useContext } from 'react'
import { pathof } from '../../../../libs/helpers/helpers'
import { translations } from '../../../../translations'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import { AreasView, Kpi } from '../../services/types'

import styles from './HeatAreas.module.scss'

export interface HeatAreasParam {
    className?: Argument
    data: AreasView
}

export const HeatAreas: FunctionComponent<HeatAreasParam> = ({ data, className }) => {
    const locale = useContext(LocaleContext)

    return (
        <div className={classNames(styles.overview, className)}>
            <div className={styles.nav}>
                <span>{locale._(pathof<typeof translations>()._('areas')._('quiet').path)}</span>
                <span>{locale._(pathof<typeof translations>()._('areas')._('busy').path)}</span>
            </div>
            <div className={styles.total}>
                <span>{data.total}</span>
                <span> {locale._(pathof<typeof translations>()._('areas')._('bins').path)}</span>
            </div>
            <div className={styles.area}>
                <div>
                    <span>
                        {[Kpi.Sufficient, Kpi.Moderate, Kpi.InSufficient].map((k, i) => {
                            return (
                                <span
                                    style={{
                                        width: `${Math.min(
                                            80,
                                            Math.max(10, ((data.counts[k] || 0) / data.total) * 100)
                                        )}%`,
                                    }}
                                    className={classNames({
                                        [styles.isSufficient]: k === Kpi.Sufficient,
                                        [styles.isModerate]: k === Kpi.Moderate,
                                        [styles.isInSufficient]: k === Kpi.InSufficient,
                                    })}
                                    key={`t_${i}`}
                                >
                                    {data.counts[k] || 0}
                                </span>
                            )
                        })}
                    </span>
                </div>
            </div>
            {data.areas.map((a, i) => {
                return (
                    <div key={`a_${i}`} className={styles.area}>
                        <div>
                            <span>{a.name}</span>
                            <span className={classNames(styles.circle, { [styles.dark]: i % 2 === 1 })} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
