import { AreasView, DataView, Kpi } from './types'

import { iqbinService } from './service'
import { RequestStatus, useRequest } from '../../Client/services/request'

export const useGetAreasView = (kpi?: Kpi): RequestStatus<AreasView | undefined> => {
    return useRequest(() => iqbinService.getAreasView(kpi), [kpi])
}

export const useGetDataView = (
    ids?: string[],
    startDate?: Date,
    endDate?: Date
): RequestStatus<DataView[] | undefined> => {
    const getPromise = () =>
        ids && startDate && endDate
            ? Promise.all(ids.map(id => iqbinService.getDataView(id, startDate, endDate))).then(r => {
                  return {
                      data: r.map(e => {
                          return e.data
                      }),
                      status: 200,
                      statusText: 'OK',
                      headers: {},
                      config: {},
                  }
              })
            : undefined

    return useRequest(getPromise, [ids, startDate, endDate])
}
