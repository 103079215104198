export function nameof<T>(key: keyof T): keyof T {
    return key
}

interface IPathOf<T> {
    readonly path: string
    _: <K extends keyof T>(name: K & PropertyKey) => IPathOf<T[K]>
}

export function pathof<T>(...pathKeys: PropertyKey[]): IPathOf<T> {
    return {
        get path() {
            return pathKeys.join('.').replace(/\.(\d+)\./g, '[$1].')
        },
        _: function <K extends keyof T>(name: K & PropertyKey) {
            pathKeys.push(name)
            return pathof<T[K]>(...pathKeys)
        },
    }
}
