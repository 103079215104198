import classNames, { Argument } from 'classnames'
import React, { FunctionComponent } from 'react'

import { TextButton } from '../../../Core/components/Button/TextButton/TextButton'
import { Icons } from '../../../Core/components/Icon/Icon'

import styles from './Panel.module.scss'

export interface PanelParam {
    clasName?: Argument
    layers?: boolean
    onLayersClick?: () => void
}

export const Panel: FunctionComponent<PanelParam> = ({ clasName, layers = true, onLayersClick }) => {
    return (
        <div className={classNames(styles.panel, clasName)}>
            <TextButton className={classNames(styles.button, { [styles.largeBottom]: true })} icon={Icons.search} />
            {layers && <TextButton className={styles.button} icon={Icons.layers} onClick={onLayersClick} />}
            <TextButton
                className={classNames(styles.button, { [styles.smallBottom]: true, [styles.autoTop]: true })}
                icon={Icons.plus}
            />
            <TextButton className={styles.button} icon={Icons.minus} />
        </div>
    )
}
