import React, { FunctionComponent, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './ResetPassword.module.scss'
import { Button } from '../../../Core/components/Button/Button'
import { UnicaLogo } from '../../../Core/components/Logo/UnicaLogo'
import { Input } from '../../../Core/components/Input/Input'
import { InfoText, TextSizeEnumType, TextStyleEnumType } from '../../../Core/components/Typography/Text/Text'
import { IQBinLogo } from '../../../Core/components/Logo/IQBinLogo'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import { authService } from '../../../Auth/services/service'
import { routes } from '../../../../routes'

export interface ResetPasswordParams {
    token: string
}

export const ResetPassword: FunctionComponent<ResetPasswordParams> = ({ token }) => {
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const history = useHistory()

    const locale = useContext(LocaleContext)

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            if (password1 !== password2) {
                setErrorMessage('Passwords are not equil.')
                return
            }

            setLoading(true)

            const result = await authService.postUpdatePassword(token, password1, password2)

            setLoading(false)

            if (result.status === 200 || result.status === 204) {
                history.push(routes.index)
                history.push(routes.login, { force: true })
            } else {
                setErrorMessage(result.statusText)
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            setLoading(false)
            setErrorMessage(e.message)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
                <IQBinLogo />
                <form className={styles.form} onSubmit={handleOnSubmit}>
                    <Input
                        type="password"
                        placeholder={locale._('login.passwordPlaceholder')}
                        onChange={v => setPassword1(v)}
                        value={password1}
                        required={true}
                    />
                    <Input
                        type="password"
                        placeholder={locale._('login.confirmPasswordPlaceholder')}
                        onChange={v => setPassword2(v)}
                        value={password2}
                        required={true}
                    />
                    <Button isSubmit={true} className={styles.submitButton} loading={loading}>
                        {locale._('login.change')}
                    </Button>
                    <InfoText
                        style={TextStyleEnumType.italic}
                        size={TextSizeEnumType.small}
                        className={styles.errorMessage}
                    >
                        {errorMessage}
                    </InfoText>
                </form>
                <UnicaLogo className={styles.unicaLogo} />
            </div>
        </div>
    )
}
