import styles from './Text.module.scss'
import React, { FunctionComponent } from 'react'

import classNames, { Argument } from 'classnames'

export enum FontEnumType {
    secondary = 'secondary',
}

export enum TextColorEnumType {
    secondary = 'secondary',
}

export enum TextSizeEnumType {
    small = 'small',
    large = 'large',
}

export enum TextStyleEnumType {
    italic = 'italic',
}

interface Props {
    className?: Argument
    color?: TextColorEnumType
    size?: TextSizeEnumType
    style?: TextStyleEnumType
    font?: FontEnumType
}

export const InfoText: FunctionComponent<Props> = ({ children, className, size, style, font, color }) => {
    return (
        <p
            className={classNames(styles.container, className, {
                [styles.isSizeSmall]: size === TextSizeEnumType.small,
                [styles.isSizeLarge]: size === TextSizeEnumType.large,
                [styles.isStyleItalic]: style === TextStyleEnumType.italic,
                [styles.isFontSecondary]: font === FontEnumType.secondary,
                [styles.isColorSecondary]: color === TextColorEnumType.secondary,
            })}
        >
            {children}
        </p>
    )
}
